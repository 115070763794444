// Step 1: Import React
import * as React from 'react';
import { graphql } from 'gatsby';
import { Link, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from '../components/Layout';
import { Container, Row } from "reactstrap";
import { BiLinkExternal } from "react-icons/bi";
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 


// Step 2: Define your component
const FAQ = () => {
  const { t } = useTranslation();

  return (
    <main>
      <Layout>
        <title>Frequently Asked Questions</title>
        <Container className="siteStyl">
          <br />
          <Row>
          <p><strong>{t('help1')}</strong></p>
          <p>{t('help2')} <a href="https://www.google.com/inputtools/chrome/" target="_blank" rel="noopener noreferrer">{t('help21')} <BiLinkExternal /></a> {t('help22')}</p>
          <br /><p><strong>{t('help4')}</strong></p>
          <p>{t('help5')} <Link to="/pricing">{t('help51')}</Link> {t('help52')} </p>
          <br /><p><strong>{t('help6')}</strong></p>
          <p>{t('help7')} <a href="https://support.google.com/books/partner/answer/9331459" target="_blank" rel="noopener noreferrer">{t('help71')} <BiLinkExternal /></a> {t('help72')}</p>
          <br /><p>{t('help8')}</p>
          </Row>
        </Container>
      </Layout>
    </main>
    )
}

// Step 3: Export your component
export default FAQ

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["help", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;